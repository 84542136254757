import { mapState, mapActions } from 'vuex';

import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import KnAutoComplete from '../.././../shared/components/KnAutoComplete.vue';
import KnTextField from '../.././../shared/components/KnTextField.vue';
import { getItem } from '../../../../api/api-methods';

export default {
  components: { KnTextField, KnAutoComplete },
  mixins: [validationFormMixin],
  computed: {
    ...mapState(['institutionId', 'userData', 'currentInstitution']),
    invoiceUseLabel() {
      console.log(this.model);
      const option = this.invoiceUse.find(option => option.id === this.model.uso_factura);
      return option ? option.dato : '';
    },
    fiscalRegimeLabel() {
      const option = this.regimentTypes.find(option => option.Value === this.model.regimen_fiscal);
      return option ? option.Name : '';
    },
    paymentFormLabel() {
      const option = this.paymentForms.find(option => option.Value === this.model.forma_pago);
      return option ? option.Name : '';
    },
    currencyLabel() {
      const option = this.currency.find(option => option.Value === this.model.moneda);
      return option ? option.Name : '';
    },
    cfdiTypeLabel() {
      const option = this.cfdiTypes.find(option => option.Value === this.model.tipo_cfdi);
      return option ? option.Name : '';
    },
    paymentMethodLabel() {
      const option = this.paymentMethods.find(option => option.Value === this.model.metodo_pago);
      return option ? option.Name : '';
    },
  },
  props: {
    model: {
      type: Object,
      default: () => { },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useGeneric: {
      type: Boolean,
    },
    originalTaxInfo: {
      type: Object,
    }
  },
  data() {
    return {
      invoiceUse: [],
      regimentTypes: [],
      currency: [],
      paymentForms: [],
      cfdiTypes: [],
      paymentMethods: [],
      isEditMode: false,
      localIsChecked: this.useGeneric,
    };
  },
  watch: {
    useGeneric(newValue) {
      if (newValue) {
        this.applyGenericInfo();
        this.isEditMode = false;
      } else {
        if (!this.originalTaxInfo || !this.originalTaxInfo.id) {
          this.isEditMode = true;
        }
        this.restoreOriginalInfo();
      }
    },
  },
  methods: {
    ...mapActions('facturama', ['fetchDataFacturama', 'fetchDefaultTaxInfo']),
    async loadFormOptions() {
      try {
        const { password_facturama, usuario_facturama } = await this.currentInstitution
        const formOptions = await this.fetchDataFacturama({ user: usuario_facturama, password: password_facturama });
        const usoInvoice = await getItem('/app-personas/uso-factura?limit=30');

        this.invoiceUse = usoInvoice.results;
        this.cfdiTypes = formOptions.cfdiTypes;
        this.regimentTypes = formOptions.regimentTypes;
        this.currency = formOptions.currency;
        this.paymentForms = formOptions.paymentForms;
        this.paymentMethods = formOptions.paymentMethods;

      } catch (error) {
        console.error('Error al cargar datos de Facturama', error);
      } finally {
        this.loading = false;
      }
    },

    emitChangeInfo() {
      this.$emit('changeGenericInfo', this.regimentTypes, this.invoiceUse);
    },
    emitHandleCheck(value) {
      this.$emit('changeGenericCheck', value);
    },
    emitTaxInfo() {
      this.$emit('update-tax-info', {
        taxInfo: this.model,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },
    async applyGenericInfo() {
      const genericTaxInfo = await this.fetchDefaultTaxInfo({
        user: this.currentInstitution.usuario_facturama,
        password: this.currentInstitution.password_facturama,
        institutionId: this.institutionId
      });      

      this.$emit('update-tax-info', {
        taxInfo: genericTaxInfo,
        regimentTypes: this.regimentTypes,
        invoiceUse: this.invoiceUse,
      });
    },
    restoreOriginalInfo() {
      if (this.originalTaxInfo) {
        this.$emit('update-tax-info', {
          taxInfo: this.originalTaxInfo,
          regimentTypes: this.regimentTypes,
          invoiceUse: this.invoiceUse,
        });
      }
    },
  },

  async created() {
    await this.loadFormOptions();
  }
};
